import "./MeetingDetails.scss";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCandidateId } from "../../Apis/candidateDetails";
import { MeetingDescription } from "../../Component/MeetingDetails/MeetingDescription/MeetingDescription";
import { BackToDashBoard } from "../../Component/Common/BackToDashBoard/BackToDashBoard";
import { Header } from "../../Component/Header/Header";
import { CandidateDetails } from "../../Component/MeetingDetails/CandidateDetails/CandidateDetails";
import ReportCard from "../../Component/MeetingDetails/PreviousRoundPerformance/PreviousRoundPerformance";
import LoadingOverlay from "../../Component/Common/LoadingOverlay";

export const MeetingDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [meetingDetails, setMeetingDetails] = useState({
    start: queryParams.get("start"),
    end: queryParams.get("end"),
    timezone: queryParams.get("timezone"),
    title: queryParams.get("title"),
    join_url: queryParams.get("join_url"),
    meet_id: queryParams.get("meet_id"),
  });
  const [candidateId, setCandidateId] = useState("");
  const [candidateProfile, setCandidateProfile] = useState({});
  const [disableJoin, setDisableJoin] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCandidateId = async () => {
    setLoading(true);
    try {
      const res = await getCandidateId(meetingDetails.meet_id);
      setCandidateProfile({ ...res });
      setCandidateId(res?.developerResponse?.id);

      if (
        res?.assessmentScoreRating?.softSkillsScore !== -1.0 &&
        res?.assessmentScoreRating?.softSkillsScore !== null
      ) {
        setDisableJoin(true);
      }
    } catch (err) {
      console.log("error  in handleCandidateId ==>", err);
    } finally {
      setLoading(false);
    }
  };

  // useEffect hook to fetch data and set date/time when component mounts
  useEffect(() => {
    handleCandidateId();
  }, [meetingDetails]);

  return (
    <>
      {loading && <LoadingOverlay loading={loading} />}
      <Header />
      <div className="metting-details-container">
        <BackToDashBoard />
        {candidateProfile && meetingDetails && (
          <>
            {" "}
            <MeetingDescription
              meetingDetails={meetingDetails}
              disableJoin={disableJoin}
              candidateProfile={candidateProfile}
              setLoading={setLoading}
              handleCandidateId={handleCandidateId}
            />
            <CandidateDetails candidateProfile={candidateProfile} />
            {/* <ReportCard
              candidateProfile={candidateProfile}
              meetingDetails={meetingDetails}
            />{" "} */}
          </>
        )}
      </div>
    </>
  );
};
