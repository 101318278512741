import { SUBMIT_R3_COMPLETE } from "./URL";
import axiosInstance from "./axiosInstance";

export const submitRound = async (candidateId, candidateProfile) => {
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
    header: {},
  };

  const URL = SUBMIT_R3_COMPLETE;
  const data = {
    // id: "dev.wissda@gmail.com",
    id: candidateId,
    status: true,
    stageId: candidateProfile.stageId,
  };
  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
};
