export const HR_LOGIN =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_AUTHENTICATION +
  "/api/v1/user/hr/expert/login";

export const MONTH_EVENT =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  "/meetings/completed/pending?page_size=200&page_number=1&type=scheduled";

export const PENDING_PROJECT =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  "/count/pending/project";

export const GET_CALENDAR_EVENTS =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  "/HR/meetings?page_size=200&page_number=1";

export const GET_CANDIDATE_ID =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  "/candidate/details/";

export const DOWNLOAD_RESUME =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_AZURE_STORAGE +
  `/downloadblob`;

export const VIEW_SCREEN_RECORDING =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/view/screen/recording`;

export const VIEW_VIDEO_RECORDING =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/view/video/recording`;

export const DECLINE_METTING =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/update/hr/meeting/status`;

export const GET_ALL_PROJECTS_FOR_HR =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND4 +
  `/api/v1/round4/list/projects`;

export const VIEW_ASSIGNED_PROJECT =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND4 +
  `/api/v1/round4/hr/view/project`;

export const HANDLE_SUBMIT_HR_RESULT =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/submit/hr/status`;

export const ASSIGN_PROJECT_TO_CANDIDATE =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND4 +
  `/api/v1/round4/assign/candidate/project`;

export const SUBMIT_HR_RATING =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/submit`;

export const SUBMIT_R3_COMPLETE =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_DEVELOPER +
  `/api/v1/assessment/softskillsround/status`;

export const GET_SUBMISSIONS_LIST =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  "/hr/submission";

export const SEND_OTP =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_AUTHENTICATION +
  `/api/v1/user/forgotpassword/request/otp`;

export const VERIFY_OTP =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_AUTHENTICATION +
  `/api/v1/user/forgotpassword/verify-otp`;

export const RESET_PASSWORD =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_AUTHENTICATION +
  `/api/v1/user/forgotpassword/reset-password`;

export const AVAILABILITY_RECURRING_GET =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/hr/availability/get/recurring/availability`;

export const AVAILABILITY_RECURRING_SAVE =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/hr/availability/recurring`;

export const AVAILABILITY_CUSTOM_GET =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/hr/availability/get/custom/availability`;

export const AVAILABILITY_CUSTOM_SAVE =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/hr/availability/custom`;

export const AVAILABILITY_ALL_GET =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/hr/availability/get`;

export const AVAILABILITY_OVERRIDE_SAVE =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/hr/availability/override/recurring`;

export const DELETE_RECURRING_AVAILABILITY =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/softskillsround/hr/availability/delete/recurring/availability`;

export const FLAG_USER =
  process.env.REACT_APP_BASE_URL +
  process.env.REACT_APP_ROUND3 +
  `/api/v1/hrmeeting/flag/user`;
