import "./ProjectEligibility.scss";
import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  MenuItem,
  Link,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getAllProjectsForHr,
  viewProjectAssigned,
} from "../../../Apis/getAllProjectsHr";
import { toast } from "react-toastify";
import LoadingOverlay from "../../Common/LoadingOverlay";

export const ProjectEligibility = ({
  open,
  handleClose,
  isEligible,
  setIsEligible,
  candidateProfile,
  // setProject,
  // project,
  // selectedProjectUrl,
  // setSelectedProjectUrl,
  // date,
  // setDate,
  handleProjectSubmit,
}) => {
  // const [options, setOptions] = useState([]);
  // const today = new Date().toISOString().split("T")[0];
  // const [isDisabled, setIsDisabled] = useState(true);
  // const maxDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
  //   .toISOString()
  //   .split("T")[0];

  const [loading, setLoading] = useState(false);

  const handleRadioChange = (event) => {
    setIsEligible(event.target.value === "yes");
    if (event.target.value === "no") {
      setIsEligible(false);
      // setDate("");
      // setProject("");
      // setSelectedProjectUrl("");
    }
  };
  // const handleGetAvailableProjects = async () => {
  //   try {
  //     setLoading(true);
  //     const data = {
  //       field: candidateProfile?.developerSkills["field"],
  //       role: candidateProfile?.developerSkills["subfield"],
  //     };
  //     const allProjects = await getAllProjectsForHr(data);

  //     if (allProjects) {
  //       const optionsData = allProjects?.data?.projects?.map((project) => ({
  //         value: project?.projectName,
  //         label: project?.projectName,
  //         projectUrl: project.projectUrl,
  //       }));
  //       setOptions([...optionsData]);
  //     }
  //   } catch (err) {
  //     console.log("error in handleGetAvailableProjects", err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleOptionChange = (event) => {
  //   const selectedValue = event.target.value;
  //   const selectedItem = options.find(
  //     (option) => option.value === selectedValue
  //   );

  //   // console.log("options changed ", selectedItem);
  //   setProject(selectedItem);
  //   setSelectedProjectUrl(selectedItem.projectUrl);
  //   if (date) setIsDisabled(false);
  // };

  // const handleShowProject = async () => {
  //   // console.log("data==>", ele);
  //   if (!project?.value) return;
  //   try {
  //     setLoading(true);
  //     const data = {
  //       name: candidateProfile?.developerResponse["id"],
  //       projectName: project?.value,
  //     };
  //     const res = await viewProjectAssigned(data);
  //     // console.log("handleShowProject==>", res);
  //   } catch (err) {
  //     console.log("handleShowProject error==>", err);
  //     toast("Error viewing project");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  //   if (project && project?.value) {
  //     setIsDisabled(false);
  //   }
  // };

  // useEffect(() => {
  //   if (open) handleGetAvailableProjects();
  // }, [open]);

  return (
    <>
      {loading && <LoadingOverlay loading={loading} zIndex={1500} />}
      <Modal open={open} onClose={handleClose} className="modal">
        <Paper
          className="modal"
          sx={{
            maxHeight: "80vh", // Max height to ensure content fits within the screen
            maxWidth: "90vw", // Optional, to control the width
            overflowY: "auto", // Enable vertical scrolling when content overflows
          }}
        >
          <IconButton className="close-button" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className="title">
            Is the candidate eligible for next round?
          </Typography>
          <RadioGroup
            row
            onChange={handleRadioChange}
            className="radio-group"
            value={isEligible ? "yes" : "no"}
          >
            <FormControlLabel
              value="yes"
              control={
                <Radio
                  sx={{
                    color: "#062B6E",
                    "&.Mui-checked": { color: "#062B6E" },
                  }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  sx={{
                    color: "#062B6E",
                    "&.Mui-checked": { color: "#062B6E" },
                  }}
                />
              }
              label="No"
            />
          </RadioGroup>
          {isEligible && (
            <>
              {/* <div className="availability">
                <label htmlFor="availability">Candidate Availability</label>
                <TextField
                  className="availability-date"
                  type="date"
                  id="availability"
                  name="availability"
                  fullWidth
                  variant="outlined"
                  value={date}
                  onChange={(e) => handleDateChange(e)}
                  inputProps={{ min: today, max: maxDate }}
                />
              </div> */}
              {/* <div className="project-selection">
                <label htmlFor="project">Project assigned for next round</label>
                <TextField
                  select
                  id="project"
                  name="project"
                  fullWidth
                  variant="outlined"
                  className="project-input"
                  value={project?.value || ""}
                  onChange={handleOptionChange}
                >
                  {options.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div> */}
              {/* <Link
                onClick={() => handleShowProject()}
                className="view-project"
              >
                View Project
              </Link> */}
            </>
          )}
          <Button
            className="nextButton"
            // disabled={isDisabled}
            // style={{
            //   cursor: isDisabled ? "not-allowed" : "pointer",
            // }}
            onClick={() => handleProjectSubmit()}
          >
            Submit
          </Button>
        </Paper>
      </Modal>
    </>
  );
};
